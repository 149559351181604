<template>
<div>
   <default-app-bar @click="" > 
     <template v-slot:title>
      <div class="text-h3 mr-2"> Detalle de Crédito </div>
     </template>
     <template v-slot:actions>
          <router-link  to="/admin/solicitudes/list-desembolsado"   color="primary" class="mr-2" >
            Volver
          </router-link>
          <a  small   color="primary" class="btn-margin0" elevation="1"  v-on:click="print">
            <v-icon small>mdi-file-pdf </v-icon> 
          </a>
     </template>
   </default-app-bar>

    <div v-if="info" class="px-3">
        
        <v-container>
        
           
           <v-row no-gutters> 
            <v-col cols="12" sm="12" class="form-col"  > 
              <div class="text-h3 mr-2"> Crédito Nro: {{info.id}} </div>
            <v-list>
              <v-list-item style="display: flex; flex-direction: column;">
                <div style="display:flex;justify-content: space-between !important;width:100%" v-if="!estadoCuenta">
                  <div>Próxima cuota a pagar: 

                  </div> 
                  <div>{{$filters.currency(0)}}</div>
                </div>

                <div style="display:flex;justify-content: space-between !important;width:100%" v-if="!estadoCuenta">
                  <div>Fecha próximo pago:</div> 
                  <div></div>
                </div>

                <!--div style="display:flex;justify-content: space-between !important;width:100%" v-if="lastCuota  &&  lastCuota.estado_cuota == 0">
                  <div>Próxima cuota a pagar: 

                  </div> 
                  <div>{{$filters.currency(0)}}</div>
                </div-->

                <div style="display:flex;justify-content: space-between !important;width:100%" v-if="estadoCuenta">
                  <div>Próxima cuota a pagar: 
                    <b><!--div v-if="lastCuota.estado_cuota == 3">(Pagada)</div>
                    <div v-if="lastCuota.estado_cuota == 2">(Abonada)</div-->
                    <!--div v-if="lastCuota.estado_cuota == 1">(Pendiente)</div-->
                  </b>
                  </div> 
                  <div>{{$filters.currency(info.saldo_actual )}}</div>
                </div>

                <div style="display:flex;justify-content: space-between !important;width:100%" v-if="lastCuota">
                  <div>Fecha próximo pago:</div> 
                  <div>{{$filters.date(lastCuota.fecha_limite_pago)}}</div>
                </div>

                

                <!--div style="display:flex;justify-content: space-between !important;width:100%" v-if="lastCuota">
                  <div>Fecha límite última cuota:</div> 
                  <div>{{$filters.date(lastCuota.fecha_limite_pago)}}</div>
                </div-->

                <div style="display:flex;justify-content: space-between !important;width:100%" v-if="info.saldo_actual_info">
                  <div>Valor mora:</div> 
                  <div>{{$filters.currency(info.saldo_actual_info.info.mora_xpagar)}}</div>
                </div>

                <div style="display:flex;justify-content: space-between !important;width:100%" v-if="info.saldo_actual_info">
                  <div>Días de mora:</div> 
                  <div>{{info.saldo_actual_info.dias_sin_pagar}}</div>
                </div>

                <div style="display:flex;justify-content: space-between !important;width:100%" v-if="info.saldo_actual_info">
                  <div>Saldo:</div> 
                  <div>{{$filters.currency(info.saldo_actual_info.info.total_saldo)}}</div>
                </div>

                <div style="display:flex;justify-content: space-between !important;width:100%" v-if="info.saldo_actual_info">
                  <div>Fecha valor saldo:</div> 
                  <div>{{$filters.date(info.saldo_actual_info.fecha_corte)}}</div>
                </div>


              </v-list-item>
            </v-list>

            <!--v-btn   color="primary" @click="pay" >Pagar Cuota o  realizar abono</v-btn-->
            <v-btn @click="pay" color="primary" text class="text-h5" style="cursor: pointer; margin-left: -30px">
            <v-icon class="mr-2">mdi-cash-multiple</v-icon>
            Pagos en línea y PSE</v-btn>

             <!--InfoSolicitud :data="info"></InfoSolicitud-->
            </v-col>
           </v-row>
         </v-container>
         <div class="pb-6 mt-2">
          <v-expansion-panels accordion v-model="panel" multiple  :key="reloadKey" >  

            <v-expansion-panel dense > 
              <v-expansion-panel-header color="primary" dense>
             <div class="text-subtitle-2 white-text"> Estados de Cuenta</div>
            </v-expansion-panel-header> 
                 <v-expansion-panel-content class="pt-3"> 
                  <NgInfoEstadosCuenta :id="info.id"></NgInfoEstadosCuenta>
                 </v-expansion-panel-content> 
           </v-expansion-panel>


            <v-expansion-panel v-if="$store.getters.is_super"> 
              <v-expansion-panel-header color="primary">
                <div class="text-subtitle-2 white-text">Información general</div>
              </v-expansion-panel-header> 
                 <v-expansion-panel-content class="pt-3"> 
                  <InfoSolicitud :data="info"></InfoSolicitud>
                 </v-expansion-panel-content> 
           </v-expansion-panel>

           <v-expansion-panel   v-if="$store.getters.is_super"> 
              <v-expansion-panel-header color="primary">
                <div class="text-subtitle-2 white-text">Plan de Pagos</div>
              </v-expansion-panel-header> 
                 <v-expansion-panel-content class="pt-3"> 
                 <PlanPagos :solicitud="info.id" ></PlanPagos>
                 </v-expansion-panel-content> 
           </v-expansion-panel>
           
           <v-expansion-panel v-if="$store.getters.is_super"> 
              <v-expansion-panel-header color="primary">
                <div class="text-subtitle-2 white-text">Cuotas</div>
              </v-expansion-panel-header> 
                 <v-expansion-panel-content class="py-4"> 
                 <CuotasCredito :solicitud="info.id" ></CuotasCredito>
                 </v-expansion-panel-content> 
           </v-expansion-panel>
           
        
      </v-expansion-panels>
     </div>
  </div>
</div>
</template>

<script>
  import InfoSolicitud from './InfoSolicitud';
  import PlanPagos from './PlanPagos';
  import CuotasCredito from './CuotasCredito';
  import NgInfoEstadosCuenta from './NgInfoEstadosCuenta';

  export default {
    name: 'DetailCredit',
    components: { InfoSolicitud, PlanPagos, CuotasCredito, NgInfoEstadosCuenta },
    data () {
      return {
        reloadKey: 1,
        panel: null,
        info: {},
        lastCuota: null,
        estadoCuenta: null
      }
    },
    mounted(){
      this.$http.get( 'api/v1/admin/solicitudes/get/'+this.$route.params.id  )
        .then(res => {
            if(res.data.success){
              //console.log(res.data)
              this.info = res.data.item;
              this.getLastCuotaGenerada();
              this.reloadKey++;
            }
        }).catch(err => {
          console.log(err);
        });
    },
    methods:{
      pay(){
        //console.log(this.info)
        this.$router.push('/payments/'+ this.$route.params.id)
      },
      getLastCuotaGenerada(){
        if(this.info){
          this.estadoCuenta = this.info.saldo_actual_info;
          if(this.info.last_cuotas.length == 2){
            this.lastCuota = this.info.last_cuotas[0];
            if(this.lastCuota.cuota != this.info.plazo){
              this.lastCuota = this.info.last_cuotas[1];
            }
            
          }else{
            this.lastCuota = this.info.last_cuotas[0];
          }
        }
      }
    }

  }
</script>
