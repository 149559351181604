<template>
<div >
  <v-list v-if="items && items.length > 0">
    <template v-for="item in items">
      <v-list-item >
        <v-list-item-content>
             <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
             <span class="b1">Cuota Nro: {{item.cuota}} </span>
             <span class="b1">Fecha Corte: {{$filters.date(item.fecha_corte)}}</span> 
             </div>
             <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
             <span class="">Total </span>
             <span class="">{{$filters.currency(item.total_cuota)}}</span> 
             </div>
            <div>{{item.bus_solicitud_id.name}}</div>
            
            
             
          </v-list-item-content>
          
          <v-list-item-action>

        <v-list-item>
            <v-btn  icon   dark  color="primary" title="Ver"  elevation="1"  
            v-on:click="executeItemAction({ name: 'pdf', hook: 'hook_print_pdf_estado_cuenta'}, item)">
              <v-icon>mdi-file-pdf-box</v-icon> 
            </v-btn>
        </v-list-item>
      
          </v-list-item-action>
          
          </v-list-item>
          <v-divider :key="item.id"></v-divider>
    </template>
  </v-list>
  <div v-else>
    <b>No se han generado estados de cuenta</b>
  </div>
</div>

</template>

<script>

  export default {
    name: 'NgInfoEstadosCuenta',
    data () {
      return {
        items: []
      }
    },
    props: ['id'],
    mounted(){
      this.loadInfo();
    },
    methods: {
      loadInfo(){
        // 
        this.$http.post('api/v1/admin/estado-cuenta/list', { data: { bus_solicitud_id: this.id }, _paginate: { page: 1, max_rows: 60}, _order: [{ field: 'cuota', direction: 'DESC'}] }, this, true).then( response => {
          //console.log(response)
          if(response.success){
            this.items = response.items;
          }
        })
      },
  
      executeItemAction(action, item){
        let hook = '';
        if(action.hook){
            hook = action.hook;
        }
        if(window['managerHooks'].hasHook(hook)){
          window['managerHooks'].executeModuleAction(hook, action, item, this);
        }else{
          this.$router.push(action.path + '/' + item.id);
        }
        
      }
    }
   
  }
</script>
